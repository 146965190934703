<template>
  <div class="commodity-warehousing-detail">
    <div class="header">
      <div class="infomation"
           v-if="$route.params.id">
        <van-cell title="入库单号"
                  :value="detail.warehousingNumber" />
      </div>
      <van-form @submit="onSubmit"
                class="form"
                :class="{ disabled: detail.status == 'SUBMITTED' }"
                ref="form">
        <div>
          <van-field v-model="detail.warehousingType"
                     name="入库类型"
                     label="入库类型"
                     readonly
                     required
                     clickable
                     @click="showWarehousingType = true"
                     placeholder="请选择"
                     :rules="[{ required: true, message: '入库类型不能为空' }]" />
          <van-popup v-model="showWarehousingType"
                     round
                     position="bottom">

            <custom-popup title="入库类型"
                          value-key="value"
                          :columns="warehousingTypeColumns"
                          @cancel="showWarehousingType = false"
                          @confirm="warehousingTypeConfirm"></custom-popup>
          </van-popup>
        </div>
        <div>
          <van-field v-model="detail.supplierName"
                     name="来源单位名称"
                     label="来源单位名称"
                     readonly
                     clickable
                     @click="showSupplier = true"
                     placeholder="请选择" />
          <van-popup v-model="showSupplier"
                     round
                     position="bottom">

            <custom-popup title="来源单位"
                          value-key="value"
                          :columns="supplierColumns"
                          @cancel="showSupplier = false"
                          @confirm="supplierConfirm"></custom-popup>
          </van-popup>
        </div>
        <div>
          <van-field v-model="warehouseComputed"
                     name="仓库名称"
                     label="仓库名称"
                     readonly
                     required
                     clickable
                     @click="showWarehouse = true"
                     placeholder="请选择"
                     :rules="[{ required: true, message: '仓库名称不能为空' }]" />
          <van-popup v-model="showWarehouse"
                     round
                     position="bottom">

            <custom-popup title="仓库"
                          value-key="name"
                          :columns="warehouseColumns"
                          @cancel="showWarehouse = false"
                          @confirm="warehouseConfirm"
                          v-if="warehouseColumns.length" />
            <van-empty description="暂无数据"
                       v-if="!warehouseColumns.length" />
          </van-popup>
        </div>
        <div class="action">
          <van-button class="btn"
                      round
                      block
                      type="info"
                      native-type="button"
                      :disabled="detail.status == 'SUBMITTED'"
                      @click="save('SUBMITTED')">提交</van-button>
          <van-button class="btn"
                      round
                      block
                      type="info"
                      native-type="button"
                      :disabled="detail.status == 'SUBMITTED'"
                      @click="save('STAGING')">暂存</van-button>
          <van-button class="btn"
                      round
                      block
                      type="danger"
                      native-type="button"
                      @click="del"
                      v-if="$route.params.id">删除</van-button>
        </div>
      </van-form>
    </div>
    <div class="main"
         :class="{ disabled: detail.status == 'SUBMITTED' }">
      <div class="detail">
        <item-card title="物品入库明细">
          <template slot="status">
            <span class="btn"
                  v-if="detail.warehouseId"
                  @click="storehouse=true">添加</span>
            <span class="disable_btn"
                  v-if="!detail.warehouseId"
                  @click="()=>{$utils.warning('请先选择仓库')}">添加</span>
          </template>
          <!-- <div class="action">
            <span class="btn"
                  @click="manualAdd">手动添加</span>
            <span class="btn"
                  @click="scanAdd">扫码添加</span>
          </div> -->
          <div class="list">
            <div class="list-item"
                 v-for="(item, index) in detail.items"
                 :key="item.id"
                 @click="itemClick(item, index)">
              <div class="fields">{{ item.barcode }} - {{ item.name }}</div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">规格型号:</span>
                  <span class="field-value">{{ item.specifications }}</span>
                </div>
              </div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">数量:</span>
                  <span class="field-value">{{ item.warehousingQuantity }}</span>
                </div>
              </div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">存放货位:</span>
                  <span class="field-value">{{ item.stockSite }} - {{ item.code }}</span>
                </div>
              </div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">单价:</span>
                  <span class="field-value">{{ item.unitPrice | numberToFixed }}</span>
                </div>
              </div>
              <div class="delete-icon"
                   @click.stop="deleteCommodity(item, index)">
                <van-icon name="cross" />
              </div>
            </div>
          </div>
          <van-empty description="暂无数据"
                     v-if="!detail.items.length" />
        </item-card>
      </div>

      <!-- 编辑物品信息弹框 -->
      <van-popup class="dialog"
                 v-model="showDialog"
                 :close-on-click-overlay="false">
        <van-form ref="dialogForm">
          <!-- <van-field v-model="dialog.name" name="物品" label="物品" readonly /> -->
          <span class="dialog-title">{{ dialog.name }}</span>
          <van-field v-model="dialog.stockSite"
                     name="存放货位"
                     label="存放货位"
                     required
                     :readonly="stockSiteType"
                     placeholder="请填写"
                     @click="stockSiteClick"
                     :rules="[{ required: true, message: '存放货位不能为空' }]">
            <template #right-icon>
              <!-- <van-icon name="edit" @click.stop="stockSiteTypeClick" v-if="!stockSiteType" /> -->
              <van-icon name="search"
                        @click.stop="stockSiteTypeClick"
                        v-if="stockSiteType" />
            </template>
          </van-field>

          <!-- <van-field
            v-model="dialog.maxCapacity"
            name="货位存放限量"
            label="货位存放限量"
            required
            placeholder="请填写"
            type="number"
            :rules="[{ required: true, message: '货位存放限量不能为空' }]"
          /> -->
          <van-field v-model="dialog.warehousingQuantity"
                     name="入库数量"
                     label="入库数量"
                     required
                     placeholder="请填写"
                     type="digit"
                     @input="warehousingQuantityInput"
                     :rules="[{ required: true, message: '入库数量不能为空' }]" />
          <van-field v-model="dialog.unitPrice"
                     name="单价"
                     label="单价"
                     required
                     placeholder="请填写"
                     type="number"
                     :formatter="
              value => {
                return +value < 0 ? '' : value;
              }
            "
                     :rules="[{ required: true, message: '单价不能为空' }]" />
          <div class="action">
            <van-button class="btn"
                        type="info"
                        round
                        size="small"
                        @click="dialogConfirm">确定</van-button>
            <van-button class="btn"
                        type="default"
                        round
                        size="small"
                        native-type="button"
                        @click="dialogCancel">取消</van-button>
          </div>
        </van-form>
      </van-popup>

      <!-- 货位选择弹框 -->
      <van-popup v-model="showStockSite"
                 round
                 position="bottom">
        <custom-popup title="货位"
                      :columns="stockSiteColumns"
                      @cancel="showStockSite = false"
                      @confirm="stockSiteConfirm"
                      v-if="stockSiteColumns.length">
          <template #option="{option}">
            <span>{{ option.name }} - {{ option.code }} ({{ option.inventoryNumber }}/{{ option.maxCapacity }})</span>
          </template>
        </custom-popup>
        <van-empty description="暂无数据"
                   v-if="!stockSiteColumns.length" />
      </van-popup>

      <!-- 货位搜索弹框 -->
      <van-popup v-model="showStockSiteSearch"
                 position="left"
                 :style="{ width: '60%', height: '100%' }">
        <div class="stock-site-search-list"
             :style="compStyle">
          <div class="header">
            <van-search v-model="stockSiteSearchValue"
                        placeholder="货位名称/货位编号"
                        @input="stockSiteInput" />
          </div>
          <div class="main">
            <div class="stock-list">
              <div class="stock-list-item"
                   v-for="item in stockSiteSearchList"
                   :key="item.id"
                   @click="stockSiteSearchItemClick(item)">{{ item.name }}</div>
            </div>
            <van-empty v-if="stockSiteSearchList.length==0"
                       description="暂无数据" />
            <van-pagination v-if="stockSiteSearchList.length!=0"
                            v-model="currentPage.pageNumber"
                            :total-items="currentPage.totalRecords"
                            @change="currentPageChange"
                            :show-page-size="3">
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
          <div class="foot">
            <van-button class="foot-buttomn"
                        block
                        size="small"
                        type="info"
                        @click="addWarehouse">新增货位</van-button>
          </div>
        </div>
      </van-popup>

      <!-- 物品列表弹框页面 -->
      <van-popup v-model="showCommodityPage"
                 position="right"
                 :style="{ width: '100%', height: '100%' }">
        <div class="add-commodity-page">
          <div class="add-commodity-page__header">
            <div class="search-type">
              <div class="search"
                   :class="{ actived: searchAreaValue }"
                   @click="commoditySearchClick">
                <div class="search-area">
                  <van-icon name="search" />
                  <span>{{ searchAreaValueComputed }}</span>
                </div>
                <div class="clear"
                     v-if="searchAreaValue"
                     @click.stop="clear(0)">
                  <van-icon name="cross" />
                </div>
              </div>
              <div class="type"
                   :class="{ actived: commodityType }"
                   @click="commodityTypeClick">
                <span class="type-area">{{ commodityTypeComputed }}</span>
                <div class="clear"
                     @click.stop="clear(1)"
                     v-if="commodityType">
                  <van-icon name="cross" />
                </div>
              </div>
            </div>
          </div>
          <div class="add-commodity-page__main">
            <van-list class="commodity-list"
                      :immediate-check="false"
                      v-model="listStatus.loading"
                      :finished="listStatus.finished"
                      @load="onLoad">
              <div class="commodity-list-item"
                   :class="{ disabled: item.disabled }"
                   v-for="item in commodityList"
                   :key="item.id"
                   @click="commodityItemClick(item)">
                <div class="fields">
                  <div class="name">{{ item.name }}</div>
                  <div class="manufacturer">{{ item.manufacturer }}</div>
                </div>
                <div class="fields">
                  <div class="code">{{ item.code }}</div>
                </div>
              </div>
              <template #finished>
                <div v-if="commodityList.length">没有更多了</div>
              </template>
            </van-list>
            <van-empty description="暂无数据"
                       v-if="!commodityList.length" />
          </div>
          <div class="add-commodity-page__action">
            <van-button class="btn"
                        type="info"
                        round
                        @click="pageBack">返回</van-button>
          </div>

          <van-popup v-model="showComoditySearch"
                     position="top"
                     round
                     :style="{ paddingTop: '51.78px' }">
            <div class="add-commodity-page__search-wrap">
              <div class="title">搜索物品名称</div>
              <div class="search-input">
                <input class="search-input__content"
                       ref="searchInput"
                       v-model="searchContent"
                       type="text"
                       placeholder="请输入" />
              </div>
              <div class="search-action">
                <van-button class="search-btn"
                            type="info"
                            size="small"
                            round
                            @click="searchBtnClick">搜索</van-button>
              </div>
            </div>
          </van-popup>
          <van-popup v-model="showComodityType"
                     position="top"
                     round
                     :style="{ paddingTop: '51.78px' }">
            <div class="add-commodity-page__type-wrap">
              <div class="title">物品分类</div>
              <div class="commodity-type">
                <van-collapse class="commodity-type-item"
                              v-model="activeNames"
                              accordion>
                  <van-collapse-item :name="index"
                                     v-for="(item, index) in commodityTypeList"
                                     :key="item.id">
                    <template #title>
                      <div @click.stop="commodityTypeItemClick(item)">
                        <span>{{ item.name }}</span>
                      </div>
                    </template>
                    <div class="type-list"
                         v-if="item.stockItemCategorys.length">
                      <div class="type-list-item"
                           v-for="i in item.stockItemCategorys"
                           :key="i.id"
                           @click.stop="commodityTypeItemClick(i)">{{ i.name }}</div>
                    </div>
                    <div class="none-data"
                         v-if="!item.stockItemCategorys.length">暂无数据</div>
                  </van-collapse-item>
                </van-collapse>
              </div>
            </div>
          </van-popup>
        </div>
      </van-popup>

      <!-- 新增物品弹框页面 -->
      <van-popup v-model="showNewCommodityPage"
                 position="right"
                 :style="{ width: '100%', height: '100%' }">
        <div class="new-commodity-page">
          <div class="new-commodity-page__main">
            <van-form ref="newCommodityform"
                      @submit="newCommoditySubmit">
              <van-field v-model="commodityDetail.name"
                         name="物品名称"
                         required
                         label="物品名称"
                         placeholder="请填写"
                         :rules="[{ required: true, message: '物品名称不能为空' }]" />
              <van-field v-model="commodityDetail.barcode"
                         name="物品条码"
                         required
                         type="number"
                         label="物品条码"
                         placeholder="请填写"
                         :rules="[{ required: true, message: '物品条码不能为空' }]" />
              <div>
                <van-field name="物品类别"
                           v-model="stockItemCategoryIdComputed"
                           required
                           label="物品类别"
                           clickable
                           readonly
                           @click="showCommodityType = true"
                           placeholder="请选择"
                           :rules="[{ required: true, message: '物品类别不能为空' }]" />
                <van-popup v-model="showCommodityType"
                           round
                           position="bottom">
                  <van-radio-group v-model="commodityDetail.stockItemCategoryId"
                                   @change="radioChange">

                    <custom-popup title="物品类别"
                                  :columns="commodityTypeColumns"
                                  swipe-duration="500"
                                  value-key="name"
                                  select-icon="false">
                      <template #option="{option}">
                        <div class="commodity-type">
                          <van-radio :name="option.id"
                                     v-if="option.name">{{ option.name }}</van-radio>
                        </div>
                      </template>
                    </custom-popup>
                  </van-radio-group>
                </van-popup>
              </div>

              <van-field v-model="commodityDetail.specifications"
                         name="规格型号"
                         label="规格型号"
                         placeholder="请填写" />
              <van-field v-model="commodityDetail.manufacturer"
                         name="生产厂家"
                         label="生产厂家"
                         placeholder="请填写" />
              <div>
                <van-field v-model="commodityDetail.measuringUnit"
                           name="计量单位"
                           required
                           clickable
                           readonly
                           label="计量单位"
                           placeholder="请选择"
                           @click="showUnit = true"
                           :rules="[{ required: true, message: '计量单位不能为空' }]" />
                <van-popup v-model="showUnit"
                           round
                           position="bottom">

                  <custom-popup value-key="value"
                                title="计量单位"
                                :columns="unitColumns"
                                @cancel="showUnit = false"
                                @confirm="unitConfirm"></custom-popup>
                </van-popup>
              </div>
              <van-field v-model="commodityDetail.unitPrice"
                         name="物品价格"
                         required
                         label="物品价格"
                         type="number"
                         placeholder="请填写"
                         :rules="[{ required: true, message: '物品价格不能为空' }]" />
              <div>
                <van-field v-model="commodityDetail.warehouse"
                           clickable
                           readonly
                           required
                           name="仓库"
                           label="仓库"
                           placeholder="请选择"
                           @click="showAllWarehouse = true"
                           :rules="[{ required: true, message: '仓库不能为空' }]" />
                <van-popup v-model="showAllWarehouse"
                           round
                           position="bottom">

                  <custom-popup title="仓库"
                                value-key="name"
                                :columns="allWarehouseColumns"
                                @cancel="showAllWarehouse = false"
                                @confirm="allWarehouseConfirm"></custom-popup>
                </van-popup>
              </div>
              <van-field v-model="commodityDetail.inventoryLowerLimit"
                         name="库存下限"
                         required
                         label="库存下限"
                         type="number"
                         placeholder="请填写"
                         :rules="[{ required: true, message: '库存下限不能为空' }]" />
              <van-field v-model="commodityDetail.inventoryUpperLimit"
                         name="库存上限"
                         required
                         label="库存上限"
                         type="number"
                         placeholder="请填写"
                         :rules="[{ required: true, message: '库存上限不能为空' }]" />
              <div>
                <van-field v-model="commodityDetail.statusLabel"
                           clickable
                           readonly
                           name="状态"
                           label="状态"
                           placeholder="请选择"
                           @click="showStatus = true" />
                <van-popup v-model="showStatus"
                           round
                           position="bottom">

                  <custom-popup title="状态"
                                value-key="label"
                                :columns="statusColumns"
                                @cancel="showStatus = false"
                                @confirm="statusConfirm"></custom-popup>
                </van-popup>
              </div>
              <van-field v-model="commodityDetail.remark"
                         name="备注"
                         label="备注"
                         placeholder="请填写" />
              <div class="new-commodity-page__action">
                <van-button class="btn"
                            type="info"
                            size="small"
                            round
                            native-type="submit">保存</van-button>
                <van-button class="btn"
                            type="info"
                            size="small"
                            round
                            native-type="button"
                            @click="newPageBack">返回</van-button>
              </div>
            </van-form>
          </div>
        </div>
      </van-popup>

      <!-- 新增货位 -->
      <van-popup class="dialog"
                 v-model="showWarehouseDialog"
                 :style="{ width: '80%' }">
        <div style="padding:10px;">
          <van-form @submit="onWarehouseSubmit">
            <van-field label="货位名称"
                       v-model="form.name"
                       placeholder="请输入"
                       required
                       :rules="[{ required: true, message: '货位名称不能为空' }]"></van-field>
            <van-field label="最大容纳数"
                       v-model="form.maxCapacity"
                       placeholder="请输入"
                       required
                       type="digit"
                       :rules="[{ required: true, message: '最大容纳数不能为空' }]"></van-field>
            <van-field label="货位编号"
                       v-model="form.code"
                       placeholder="请输入"
                       required
                       name="validator"
                       maxlength="15"
                       :rules="[{ required: true, message: '货位编号不能为空' },{validator, message: '请输入数字或字母' }]"></van-field>
            <div class="action">
              <van-button class="btn"
                          type="info"
                          round
                          size="small">确定</van-button>
              <van-button class="btn"
                          type="default"
                          round
                          size="small"
                          native-type="button"
                          @click="showWarehouseDialog=false">取消</van-button>
            </div>
          </van-form>
        </div>
      </van-popup>

      <!--  -->
      <van-action-sheet v-model="storehouse"
                        :actions="storehouseActions"
                        @select="storehouseSelect"></van-action-sheet>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityWarehousingDetail.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";
import { flatMap, find, assign, cloneDeep, debounce } from "lodash-es";
import wxapi from "@/lib/wechat";
export default {
  name: "CommodityWarehousingDetail",
  data() {
    return {
      detail: {
        warehousingType: "",
        supplierName: "",
        warehouseId: "",
        items: []
      },
      deletedCollection: [],

      showWarehousingType: false,
      warehousingTypeColumns: [],
      showSupplier: false,
      supplierColumns: [],
      showWarehouse: false,
      warehouseColumns: [],

      showDialog: false,
      dialog: {
        name: "",
        stockSite: "",
        code: "",
        maxCapacity: "",
        warehousingQuantity: "",
        unitPrice: ""
      },
      commodityStockSiteList: [],
      stockSiteType: true,
      showStockSite: false,
      stockSiteColumns: [],
      showStockSiteSearch: false,
      stockSiteSearchValue: "",
      stockSiteSearchList: [],

      showSearch: false,
      showCommodityPage: false,
      showComoditySearch: false,
      showComodityType: false,
      activeNames: [],
      commodityType: null,
      searchContent: "",
      searchAreaValue: "",
      commodityTypeList: [],
      commodityList: [],
      listStatus: {
        loading: false,
        finished: false
      },
      page: {
        pageNumber: 0,
        pageSize: 10
      },

      showNewCommodityPage: false,
      showCommodityType: false,
      commodityTypeColumns: [],
      showUnit: false,
      unitColumns: [],
      showAllWarehouse: false,
      allWarehouseColumns: [],
      showStatus: false,
      statusColumns: [
        { label: "启用", value: "ENABLE" },
        { label: "禁用", value: "DISABLE" },
        { label: "删除", value: "DELETED" }
      ],
      commodityDetail: {
        name: "",
        barcode: "",
        stockItemCategoryId: "",
        stockItemCategory: "",
        manufacturer: "",
        measuringUnit: "",
        unitPrice: "",
        warehouseId: "",
        warehouse: "",
        inventoryLowerLimit: "",
        inventoryUpperLimit: "",
        status: "ENABLE",
        statusLabel: "启用",
        remark: ""
      },

      currentPage: {
        pageNumber: 1,
        pageSize: 10,
        totalRecords: 0
      },

      screenHeight: document.body.scrollHeight - 150,
      showWarehouseDialog: false,
      form: {
        name: "",
        maxCapacity: "",
        code: ""
      },
      storehouse: false,
      storehouseActions: [
        { name: "手动添加", key: "manualAdd" },
        { name: "扫码添加", key: "scanAdd" }
      ]
    };
  },
  computed: {
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "";
      let item = find(this.warehouseColumns, item => item.id == this.detail.warehouseId);
      return item ? item.name : "";
    },
    searchAreaValueComputed() {
      return this.searchAreaValue ? this.searchAreaValue : "物品搜索";
    },
    commodityTypeComputed() {
      if (!this.commodityTypeList.length) return "物品类别";
      let item = find(this.flatCommodityType, item => item.id == this.commodityType);
      return item ? item.name : "物品类别";
    },
    stockItemCategoryIdComputed() {
      if (!this.commodityTypeColumns.length) return "";
      let item = find(this.flatCommodityType, item => item.id == this.commodityDetail.stockItemCategoryId);
      return item ? item.name : "";
    },
    compStyle() {
      return {
        "--height": this.screenHeight + "px",
        "--fontSize": this.screenHeight / 100 + "px"
      };
    }
  },

  methods: {
    async getWarehousingDetail() {
      let ret = await this.$api.getStockWarehousingDetail({
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      this.detail = data;
    },
    async getWarehousingType() {
      let query = "name[sin]入库类型";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.warehousingTypeColumns = ret.data["入库类型"][0].dictOptions;
    },
    async getUnits() {
      let query = "name[sin]计量单位";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.unitColumns = ret.data["计量单位"][0].dictOptions;
    },
    async getStockSiteList(warehouseId, stockItemId) {
      let params = {
        warehouseId,
        stockItemId
      };
      let ret = await this.$api.getStockSiteList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteColumns = ret.data;
    },
    async getWarehouseStockSiteList({ stockItemId = null, warehouseId, query = "(status=SUCCESS)" }) {
      let params = {
        pageNumber: this.currentPage.pageNumber - 1,
        pageSize: this.currentPage.pageSize,
        stockItemId,
        sort: encodeURI(`[{"fieldName":"code","direction":"asc"}]`)
      };
      params.query = query;
      return this.$api.getWarehouseStockSiteList({
        params,
        placeholder: {
          id: warehouseId
        }
      });
    },
    getStockItemByBarCode(barcode) {
      return this.$api.getStockItemByBarCode({
        params: {
          barCode: barcode
        }
      });
    },
    addStockWarehouing(status) {
      let items = this.detail.items.map(item => {
        return {
          stockItemId: item.stockItemId,
          warehousingQuantity: item.warehousingQuantity,
          unitPrice: item.unitPrice,
          stockSite: item.stockSite,
          warehouseStockRackId: item.warehouseStockRackId,
          status,
          maxCapacity: item.maxCapacity
        };
      });
      let params = {
        warehousingType: this.detail.warehousingType,
        supplierName: this.detail.supplierName,
        warehouseId: this.detail.warehouseId,
        status,
        items
      };
      return this.$api.addStockWarehouing({ params });
    },
    setStockWarehousing(status) {
      let items = this.detail.items.map(item => {
        return {
          id: item.id,
          stockItemId: item.stockItemId,
          warehousingQuantity: item.warehousingQuantity,
          unitPrice: item.unitPrice,
          stockSite: item.stockSite,
          warehouseStockRackId: item.warehouseStockRackId,
          status,
          maxCapacity: item.maxCapacity
        };
      });
      let params = {
        warehousingType: this.detail.warehousingType,
        supplierName: this.detail.supplierName,
        warehouseId: this.detail.warehouseId,
        status,
        items
      };
      return this.$api.setStockWarehousing({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    deleteStockWarehouingItems(ids) {
      let params = {
        ids: ids.join(",")
      };
      return this.$api.deleteStockWarehouingItems({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    deleteStockWarehouings() {
      let ids = this.$route.params.id;
      let params = {
        ids
      };
      return this.$api.deleteStockWarehouings({ params });
    },

    getStockItemList({ name = null, barcode = null, categoryId = null, query = null, warehouseId = null }, isPagination = true) {
      let params;
      if (isPagination) {
        params = {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
          categoryId,
          name,
          barcode,
          query,
          warehouseId
        };
      } else {
        params = {
          page: false
        };
        let query = [{ key: "status", value: "ENABLE", oper: "equalTo" }];
        if (barcode) query.push({ key: "barcode", value: barcode, oper: "equalTo" });
        if (name) query.push({ key: "name", value: name, oper: "equalTo" });
        if (categoryId) query.push({ key: "categoryId", value: categoryId, oper: "equalTo" });
        params.query = this.getQuery(query);
      }
      return this.$api.getStockItemList({ params });
    },

    async getList(sign = false) {
      let payload = {
        // categoryId: this.commodityType != "" ? this.commodityType : null,
        categoryId: this.commodityType,
        name: this.searchContent != "" ? this.searchContent : null,
        warehouseId: this.detail.warehouseId
      };
      let ret = await this.getStockItemList(payload);
      if (ret.code != SUCCESS_CODE) return;
      ret.data.forEach(item => {
        let commodity = find(this.detail.items, i => i.stockItemId == item.id);
        item.disabled = commodity ? true : false;
      });
      if (sign) {
        this.commodityList = this.commodityList.concat(ret.data);
      } else {
        this.commodityList = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery(query) {
      // (&(xxx=xxx)(xxx=xxx))
      // [{key:xxx,value:xxx,oper:xxx}]
      let filters = query.reduce((prev, item) => {
        let filter = Filter.attribute(item.key)[item.oper](item.value);
        prev.push(filter);
        return prev;
      }, []);
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    async getStockItemType() {
      let ret = await this.$api.getStockItemType();
      if (ret.code != SUCCESS_CODE) return;
      let data1 = JSON.parse(JSON.stringify(ret.data));
      let data2 = JSON.parse(JSON.stringify(ret.data));
      this.commodityTypeList = data1;
      this.flatCommodityType = flatMap(data1, item => {
        return [item, ...item.stockItemCategorys];
      });
      data2.forEach(item => {
        if (item.stockItemCategorys.length) {
          item.children = item.stockItemCategorys;
        } else {
          item.children = [{ name: "", id: "" }];
        }
      });
      this.commodityTypeColumns = data2;
    },
    async getWarehouseList() {
      let params = {
        // useType: "WAREHOUSE_KEEPER"
        useType: "WAREHOUSE_MANAGER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = ret.data;
      this.allWarehouseColumns = ret.data;
    },
    async getSupplierList() {
      let query = "name[sin]来源单位";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.supplierColumns = ret.data["来源单位"][0].dictOptions;
    },
    warehousingTypeConfirm(arg) {
      this.detail.warehousingType = arg.value;
      this.showWarehousingType = false;
    },
    supplierConfirm(arg) {
      this.detail.supplierName = arg.value;
      this.showSupplier = false;
    },
    warehouseConfirm(arg) {
      this.detail.warehouseId = arg.id;
      this.showWarehouse = false;
    },
    warehousingQuantityInput(value) {
      value = value.replaceAll(/^0+/g, "");
      this.dialog.warehousingQuantity = value;
    },
    dialogConfirm() {
      this.$refs.dialogForm
        .validate()
        .then(() => {
          let item = this.detail.items[this.dialogIndex];
          assign(item, this.dialog);
          this.showDialog = false;
        })
        .catch(() => {});
    },
    dialogCancel() {
      this.dialog = cloneDeep(this.dialogCopy);
      this.$refs.dialogForm.resetValidation();
      this.showDialog = false;
    },
    deleteCommodity(item, index) {
      if (item.id) {
        this.deletedCollection.push(item.id);
      }
      this.detail.items.splice(index, 1);
    },
    manualAdd() {
      if (!this.detail.warehouseId) {
        return this.$utils.warning("请先选择仓库");
      }
      this.showCommodityPage = true;
      this.page.pageNumber = 0;
      this.getInitialData();
    },
    getInitialData() {
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      // this.commodityList = [];
      this.getList();
    },
    pageBack() {
      this.showCommodityPage = false;
    },

    getCode() {
      return new Promise(resolve => {
        wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode", "barCode"],
          success: res => {
            var result = res.resultStr;
            var code = result.split(",")[1];
            resolve(code);
          }
        });
      });
    },
    async scanAdd() {
      if (!this.detail.warehouseId) {
        return this.$utils.warning("请先选择仓库");
      }
      this.getCode().then(async code => {
        let payload = {
          barcode: code,
          warehouseId: this.detail.warehouseId
        };
        let ret = await this.getStockItemList(payload, false);
        if (ret.code != SUCCESS_CODE) return;
        let data = ret.data;
        if (!data.length) {
          this.$dialog
            .confirm({
              title: "提示",
              message: "当前物品目录没有此物品, 是否新增?"
            })
            .then(async () => {
              this.showNewCommodityPage = true;
              this.addCommodity(code);
            })
            .catch(() => {});
        } else {
          this.pushCommodity(data[0]);
        }
      });
    },
    async addCommodity(barcode) {
      let is69 = /^69/g.test(barcode);
      let commodityInfo;
      let warehouseId = this.detail.warehouseId;
      let warehouse = this.warehouseColumns.find(item => item.id == this.detail.warehouseId).name;
      if (is69) {
        let ret = await this.getStockItemByBarCode(barcode);
        if (ret.code != SUCCESS_CODE) return this.$utils.warning("数据查询为空");
        let data = ret.data;
        let { productName, type, manufacturerName } = data.productDataRecords[0];
        commodityInfo = {
          name: productName,
          specifications: type,
          manufacturer: manufacturerName,
          barcode,
          warehouseId,
          warehouse
        };
      } else {
        commodityInfo = {
          warehouseId,
          warehouse,
          barcode
        };
      }
      assign(this.commodityDetail, commodityInfo);
    },
    pushCommodity(data) {
      let isExist = this.detail.items.find(item => item.stockItemId == data.id);
      if (isExist) {
        this.$utils.warning("不可添加重复的物品");
      } else {
        data.stockItemId = data.id;
        this.detail.items.push(data);
        let lastOneIndex = this.detail.items.length - 1;
        this.itemClick(this.detail.items[lastOneIndex], lastOneIndex);
      }
    },
    async itemClick(item, index) {
      if (!this.detail.warehouseId) {
        this.$utils.warning("请先选择仓库");
        return;
      }
      this.dialog = cloneDeep(this.dialogCopy);
      this.dialogIndex = index;
      this.showDialog = true;
      assign(this.dialog, item);
      let ret = await this.getWarehouseStockSiteList({ warehouseId: this.detail.warehouseId, stockItemId: item.stockItemId });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteColumns = ret.data;
      if (this.stockSiteColumns[0]) this.stockSiteConfirm(this.stockSiteColumns[0]);
    },
    stockSiteClick() {
      if (this.stockSiteType) {
        this.showStockSite = true;
      }
    },
    async stockSiteTypeClick() {
      // this.stockSiteType = !this.stockSiteType;
      this.currentPage.pageNumber = 1;
      this.showStockSiteSearch = true;
      let ret = await this.getWarehouseStockSiteList({
        warehouseId: this.detail.warehouseId,
        query: `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId}))`
      });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteSearchList = ret.data;
      this.currentPage.totalRecords = ret.totalRecords;
    },
    async currentPageChange(page) {
      this.currentPage.pageNumber = page;
      let exp1 = /[\u4e00-\u9fa5]+/;
      let exp2 = /[a-zA-Z0-9]+/;
      let query = `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId}))`;
      if (exp1.test(this.stockSiteSearchValue)) {
        query = `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId})(name=*${this.stockSiteSearchValue}*))`;
      } else if (exp2.test(this.stockSiteSearchValue)) {
        query = `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId})(code=*${this.stockSiteSearchValue}*))`;
      }
      let ret = await this.getWarehouseStockSiteList({ warehouseId: this.detail.warehouseId, query });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteSearchList = ret.data;
      this.currentPage.totalRecords = ret.totalRecords;
    },
    stockSiteConfirm(arg) {
      this.dialog.stockSite = arg.name;
      this.dialog.code = arg.code;
      this.dialog.maxCapacity = arg.maxCapacity;
      this.dialog.warehouseStockRackId = arg.id;
      this.showStockSite = false;
    },
    stockSiteInput: debounce(
      async function (queryString) {
        queryString = queryString.replace(/[ ]/g, "");
        this.stockSiteSearchValue = queryString;
        if (!this.detail.warehouseId) {
          return this.$utils.warning("请先选择仓库");
        }
        // if (queryString == "") {
        // this.stockSiteSearchList = [];
        // return;
        // }

        this.currentPage.pageNumber = 1;

        let exp1 = /[\u4e00-\u9fa5]+/;
        let exp2 = /[a-zA-Z0-9]+/;
        let query = `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId}))`;
        if (exp1.test(queryString)) {
          query = `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId})(name=*${queryString}*))`;
        } else if (exp2.test(queryString)) {
          query = `(&(status=SUCCESS)(warehouseId=${this.detail.warehouseId})(code=*${queryString}*))`;
        }
        let ret = await this.getWarehouseStockSiteList({ warehouseId: this.detail.warehouseId, query });
        if (ret.code != SUCCESS_CODE) return;
        this.stockSiteSearchList = ret.data;
        this.currentPage.totalRecords = ret.totalRecords;
      },
      300,
      { trailing: true }
    ),
    stockSiteSearchItemClick(item) {
      this.dialog.stockSite = item.name;
      this.dialog.maxCapacity = item.maxCapacity;
      this.dialog.warehouseStockRackId = item.id;
      this.stockSiteSearchList = [];
      this.stockSiteSearchValue = "";
      this.showStockSiteSearch = false;

      this.stockSiteColumns.push(item);
      // this.stockSiteColumns=this.stockSiteColumns
    },
    commoditySearchClick() {
      this.showComoditySearch = !this.showComoditySearch;
      this.showComodityType = false;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },

    commodityTypeClick() {
      this.showComodityType = !this.showComodityType;
      this.showComoditySearch = false;
    },
    searchBtnClick() {
      this.showComoditySearch = false;
      this.searchAreaValue = this.searchContent;
      this.getInitialData();
    },
    commodityTypeItemClick(item) {
      this.commodityType = item.id;
      this.showComodityType = false;
      this.getInitialData();
    },
    clear(arg) {
      if (arg) {
        this.commodityType = null;
        this.showComodityType = false;
      } else {
        this.searchAreaValue = "";
        this.searchContent = "";
        this.showComoditySearch = false;
      }
      this.getInitialData();
    },
    commodityItemClick: debounce(
      function (arg) {
        if (arg.disabled) return;
        let item = find(this.detail.items, i => i.stockItemId == arg.id);
        if (!item) {
          arg.stockItemId = arg.id;
          delete arg.id;
          this.detail.items.push(arg);
          let lastOneIndex = this.detail.items.length - 1;
          this.itemClick(this.detail.items[lastOneIndex], lastOneIndex);
        }
        this.showCommodityPage = false;
      },
      300,
      { leading: true, trailing: false }
    ),
    onLoad() {
      this.getList(true);
    },
    onSubmit() {
      console.log("submit");
    },
    check() {
      if (!this.detail.items.length) {
        this.$utils.warning("请至少添加一个物品");
        return false;
      }
      let result = this.detail.items.some(item => {
        return !item.warehousingQuantity || !item.stockSite || item.unitPrice < 0;
      });
      if (result) {
        this.$utils.warning("请填写物品完整信息");
        return false;
      }
      return true;
    },
    save(status) {
      this.$refs.form
        .validate()
        .then(async () => {
          if (!this.check()) return;
          if (this.$route.params.id) {
            if (this.detail.status == "SUBMITTED") return;

            if (this.deletedCollection.length) {
              let ret = await this.deleteStockWarehouingItems(this.deletedCollection);
              if (ret.code != SUCCESS_CODE) return;
            }
            let ret = await this.setStockWarehousing(status);
            if (ret.code != SUCCESS_CODE) return;
            this.$utils.success("保存成功");
            setTimeout(async () => {
              await this.$router.go(-1);

              setTimeout(() => {
                window.scrollTo(0, 0);
                this.$parent.$refs[window.currentRoute].init();
              }, 500);
            }, 750);
          } else {
            let ret = await this.addStockWarehouing(status);
            if (ret.code != SUCCESS_CODE) return;
            this.$utils.success("保存成功");
            setTimeout(async () => {
              await this.$router.go(-1);

              setTimeout(() => {
                window.scrollTo(0, 0);
                this.$parent.$refs[window.currentRoute].init();
              }, 500);
            }, 750);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    del() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let ret = await this.deleteStockWarehouings();
          if (ret.code != SUCCESS_CODE) return;
          this.$utils.success("删除成功");
          setTimeout(async () => {
            await this.$router.go(-1);

            setTimeout(() => {
              window.scrollTo(0, 0);
              this.$parent.$refs[window.currentRoute].init();
            }, 500);
          }, 750);
        })
        .catch(() => {});
    },
    back() {
      setTimeout(async () => {
        await this.$router.go(-1);

        setTimeout(() => {
          window.scrollTo(0, 0);
          this.$parent.$refs[window.currentRoute].init();
        }, 500);
      }, 750);
    },
    addStockItem() {
      let params = this.commodityDetail;
      return this.$api.addStockItem({ params });
    },
    newCommoditySubmit: debounce(
      async function () {
        let ret = await this.addStockItem();
        if (ret.code != SUCCESS_CODE) return;
        this.$utils.success("新增成功");
        let data = ret.data;
        data.stockItemId = data.id;
        this.detail.items.push(data);
        this.commodityDetail = cloneDeep(this.commodityDetailCopy);
        this.showNewCommodityPage = false;
      },
      300,
      { leading: true, trailing: false }
    ),
    radioChange(arg) {
      if (!arg) return;
      let item = this.flatCommodityType.find(item => item.id == arg);
      if (item) {
        this.commodityDetail.stockItemCategory = item.name;
      }
      this.showCommodityType = false;
    },
    commodityTypeConfirm() {},
    unitConfirm(arg) {
      this.commodityDetail.measuringUnit = arg.value;
      this.showUnit = false;
    },
    allWarehouseConfirm(arg) {
      this.commodityDetail.warehouse = arg.name;
      this.commodityDetail.warehouseId = arg.id;
      this.showAllWarehouse = false;
    },
    statusConfirm(arg) {
      this.commodityDetail.statusLabel = arg.label;
      this.commodityDetail.status = arg.value;
      this.showStatus = false;
    },
    newPageBack() {
      this.commodityDetail = cloneDeep(this.commodityDetailCopy);
      this.showNewCommodityPage = false;
      this.$refs.newCommodityform.resetValidation();
    },
    async onWarehouseSubmit() {
      if (!this.detail.warehouseId) {
        this.$utils.warning("请先选择仓库");
        return;
      }
      let ret = await this.addWarehouseStockSite(this.detail.warehouseId);
      if (ret.code != SUCCESS_CODE) return;
      this.showWarehouseDialog = false;
      this.$utils.success("新增货位成功");
      this.stockSiteTypeClick();
    },
    addWarehouse() {
      this.showWarehouseDialog = true;
      this.form = {
        name: "",
        maxCapacity: "",
        code: ""
      };
    },
    addWarehouseStockSite(warehouseId) {
      let { name, maxCapacity, code } = this.form;
      let params = {
        name,
        maxCapacity,
        warehouseId,
        code
      };
      return this.$api.addWarehouseStockSite({
        params,
        placeholder: {
          id: warehouseId
        }
      });
    },
    validator(val) {
      return /^[a-zA-Z0-9]*$/.test(val);
    },
    storehouseSelect(item) {
      this.storehouse = false;
      console.log(123, item);
      this[item.key]();
    }
  },
  mounted() {
    console.log(this.$parent);

    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
    console.log(`${document.documentElement.clientHeight}`);
  },
  created() {
    this.dialogCopy = cloneDeep(this.dialog);
    this.commodityDetailCopy = cloneDeep(this.commodityDetail);
    this.getWarehousingType();
    this.getWarehouseList();
    this.getSupplierList();
    this.getStockItemType();
    this.getUnits();
    if (this.$route.params.id) {
      this.getWarehousingDetail();
    }
  }
};
</script>

<style></style>
